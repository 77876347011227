import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { useAsunto } from "../../Contexts/Asunto";

export default function Contact() {
  const { state, dispatch } = useAsunto()
  const [message,setMessage] = useState('')
  const [disabled,setDisabled] = useState(true)

  const [data, setData] = useState({
    fullname: "",
    email: "",
    comment: "",
    matter:""
  });

  const sendEmail = (e) => {
    e.preventDefault()
    var bodyFormData = new FormData();
    bodyFormData.set('email', data.email);
    bodyFormData.set('fullname', data.fullname);
    bodyFormData.set('comment', data.comment);
    bodyFormData.set('matter', data.matter);

    axios({
        method: 'post',
        url: 'https://penindiazprop.com.ar/send_perez.php',
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
    document.getElementById("formContact").reset()
    setMessage("¡Muchas gracias! en breve nos estaremos contactando con ud.")
  }

  function handleChange(e) {
    setData((d) => {
      return {
        ...d,
        [e.target.name]: e.target.value,
      };
    });
  }

  function setAsunto(asunto) {
    dispatch({ type: "setAsunto", payload: asunto });
    setData((d) => {
      return {
        ...d,
        matter:asunto,
      };
    });
  }

  console.log(data)

  return (
    <section
      id="contacto"
      className="section-width grid-2-col gap-3 mt-5r mb-5r p-2r"
    >
      <div className="contact-description">
        <h2 className="title-2 subtitle-1-contact">
          Conocé el verdadero valor de tu propiedad.
        </h2>
        <span className="title-2 subtitle-1-fake-contact">Contactános</span>
        <p className="paragraph-1 mt-2r mb-3r">
          Completá el siguiente formulario con los datos básicos de tu propiedad
          y un agente especializado se pondrá en contacto a la brevedad.
        </p>
      </div>

      <div>
        <h3 className="title-3 subtitle-2">
          Completá el siguiente formulario.
        </h3>
        <form className="d-flex flex-column gap-3" id="formContact" onSubmit={sendEmail}>
          <input
            type="text"
            name="fullname"
            placeholder="Nombre y Apellido"
            className="input-contact"
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="E-mail*"
            className="input-contact"
            onChange={handleChange}
            required
          />
          <div className="select-wrapper">
            <select
              name="matter"
              className={`input-contact ${state.asunto===""?"text-gray-2 font-size-16":""}`}
              value={state.asunto}
              onChange={(e) => setAsunto(e.target.value) + handleChange}
            >
              <option value="Contacto web" className="text-gray-1">
                Asunto
              </option>
              <option value="Venta">Venta</option>
              <option value="Alquiler">Alquiler</option>
              <option value="Tasación">Tasación</option>
            </select>
          </div>

          <textarea
            name="comment"
            placeholder="Envíanos tu consulta o comentario"
            className="input-contact h-7r"
            onChange={handleChange}
          ></textarea>
          <span className="text-end">* Campo obligatorio</span>
          <ReCAPTCHA
            sitekey="6LeMqp0bAAAAAIRyudT1K_ygcAurnT5o3N0copf1"
            onChange={() => setDisabled(false)}
          />
          <input type="submit" disabled={disabled} className="red-button" value="ENVIAR" />
          <p className={' text-success mt-4'}>{message}</p>
        </form>
      </div>
    </section>
  );
}
