import { useReducer, createContext, useContext } from "react";

const initialState = {
  asunto: "",
};

const reducer = (state, action) => {
  console.log(state,action)
  switch (action.type) {
    case "setAsunto":
      return {asunto: action.payload};
    default:
      return state;
  }
};

const Asunto = createContext(initialState);

export const ProviderAsunto = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Asunto.Provider value={{ state, dispatch }}>
      {children}
    </Asunto.Provider>
  );
};

export const useAsunto = () => useContext(Asunto);