import React from "react";
import RightIcon from "../../assets/svgs/right-icon.svg";
import SVG from "../Globals/SVG";
import { useAsunto } from "./../../Contexts/Asunto";

export default function FixedLeftButton() {
  const { dispatch } = useAsunto();

  function setAsunto() {
    dispatch({ type: "setAsunto", payload: "Tasación" });
  }
  
  return (
    <a onClick={setAsunto} href="#contacto" className="fixed-left-button">
      <div>TASAMOS HOY</div>
      <SVG svg={RightIcon} alt="tasamos hoy" className="mt-2" />
    </a>
  );
}
