import React from "react";
const Home = () => {
  return (
    <section className="home">
      <div>
        <h1><span className="text-white-1">¿Querés Vender? 
          <br />
          ¿Querés Comprar?
          <br />
          Nosotros lo hacemos Realidad</span></h1>
        {/* <a href="#contact" className="contact-button">CONTACTANOS</a> */}
      </div>
      <div className="footer-gradient"></div>
    </section>
  );
};

export default Home;
