import React from "react";

export default function SeeMore() {
  return (
    <section>
      <div className="see-more-info">
        <div>
          <h2 className="title-2 mb-2r">
            Conocé <strong>todas las oportunidades</strong> que tenemos para
            vos, a un solo click.
          </h2>
          <a
            href="https://www.zonaprop.com.ar/inmobiliarias/esteban-e.-perez-penin-diaz-propiedades_17014163-inmuebles-orden-publicado-descendente.html"
            className="red-button px-5"
            target="_blank"
            rel="noreferrer"
          >
            VER MÁS
          </a>
        </div>
      </div>
      <div className="see-more-background-image"></div>
    </section>
  );
}
