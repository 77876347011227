import "./assets/styles/App.scss";

import Header from "./components/Header/Header";
import Home from "./components/Content/Home";
import Footer from "./components/Footer/Footer";
import SeeMore from "./components/Content/SeeMore";
import Contact from "./components/Content/Contact";
import WhatsAppButton from "./components/Content/WhatsAppButton";
import FixedLeftButton from "./components/Content/FixedLeftButton";

import { useAsunto } from "./Contexts/Asunto";

function App() {
  const { state } = useAsunto();

  return (
    <div className="app">
      <Header />
      <div className="s">
        <Home />
        <SeeMore />
        <Contact />
        <Footer />
      </div>
      <WhatsAppButton />
      <FixedLeftButton />
    </div>
  );
}

export default App;
