import React, { useEffect, useState } from "react";
import SVG from "../Globals/SVG";
import WhatsAppIcon from "../../assets/svgs/whatsapp-button-icon.svg";
import { getWhatsapp } from "../../functions/contact-functions";

export default function WhatsAppButton() {

  const phone = '+5491165919185'

  return (
    <a
      href={getWhatsapp()}
      className="whatsapp-button"
      target="_blank"
      rel="noreferrer"
    >
      <SVG svg={WhatsAppIcon} alt="whatsapp" />
    </a>
  );
}
